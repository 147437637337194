import {
  DmmGrpcApi,
  TokenStatic,
  TokenFactoryStatic
} from '@injectivelabs/sdk-ts'
import { LocalStorage } from '@injectivelabs/utils'
import {
  NETWORK,
  USE_ARCHIVAL_NODE,
  DMM_SERVICE_ENDPOINT,
  KAVA_DMM_SERVICE_ENDPOINT
} from '@/app/utils/constant'
import { tokens } from '@/app/json'

export const tokenFactoryStatic = new TokenFactoryStatic(
  tokens as TokenStatic[]
)

export const dmmGrpcApi = new DmmGrpcApi(DMM_SERVICE_ENDPOINT)
export const kavaDmmGrpcApi = new DmmGrpcApi(KAVA_DMM_SERVICE_ENDPOINT)

export const localStorage: LocalStorage = new LocalStorage(
  `inj-dmm2-v1-${NETWORK}-${USE_ARCHIVAL_NODE ? 'archival' : 'mainnet'}`
)
