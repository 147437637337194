import { defineStore } from 'pinia'
import { NetworkErrorType } from '@/types'

type AppStoreState = {
  networkErrorTypes: NetworkErrorType[]
}

const initialStateFactory = () => ({
  networkErrorTypes: []
})

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => initialStateFactory(),
  actions: {
    async init() {
      //
    }
  }
})
