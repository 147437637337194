import { I18nMessageFunction } from '@/types'

export default {
  dmm: {
    hero: {
      liquidity: 'Liquidity',
      currentEpoch: 'Current Epoch',
      timeLeftInEpoch: 'Time Left in Epoch',
      thisEpochsRewards: "This Epoch's Rewards",
      openLiquidityProgram: 'Open Liquidity Program',
      earnRewards:
        'Earn rewards as market makers on Injective. Open and non-custodial.',
      automatedTradingDapp: 'automated trading dApp.',
      provideLiquidity: ({ named, interpolate }: I18nMessageFunction) =>
        interpolate([
          'Provide liquidity now by integrating directly with Injective, or use an ',
          named('automatedTradingDapp')
        ])
    },

    markets: {
      apy: 'APY',
      market: 'Market',
      title: 'Markets',
      liquidity: 'Liquidity',
      allMarkets: 'All Markets',
      dailyVolume: '24H Volume',
      epochRewards: 'Est. Epoch Rewards '
    },

    scores: {
      title: 'Scores',
      uptime: 'Uptime',
      address: 'Address',
      download: 'Download',
      moreInfo: 'More info',
      totalScore: 'Total Score',
      totalVolume: 'Total Vol.',
      makerVolume: 'Maker Volume',
      seeSnapshots: 'See Snapshots',
      estimatedReward: 'Est. Reward',
      liquidityScore: 'Liquidity Score',
      dailyMakerVolume: 'Daily Maker Vol.',
      dailyMakerVolumeShare: 'Daily Maker Vol. Share',
      allScoresCsv: 'All Markets - All Scores CSV',
      totalScoreCsv: 'All Markets - Total Score CSV',
      liquidityScoreCsv: 'All Markets - Liquidity Score CSV',
      uptimeScoreCsv: 'All Markets - Uptime Score CSV',
      volumeScoreCsv: 'All Markets - Volume Score CSV'
    },

    rewards: {
      address: 'Address',
      estimatedRewards: 'Estimated Rewards',
      earnedRewards: 'Earned Rewards',
      title: 'Rewards',
      eligibilityDetails: 'Eligibility Details'
    },

    eligibility: {
      title: 'Eligibility',
      makerContribution: 'Maker Contribution',
      enterAddress: 'Enter an address here',
      time: 'Time (UTC)',
      address: 'Address',
      eligible: 'Eligible',
      timestamp: 'Timestamp',
      nextEpoch: 'Next Epoch',
      ineligible: 'Ineligible',
      currentEpoch: 'Current Epoch',
      liquidityScore: 'Liquidity Score',
      seeHere: 'See here for more info.',
      lastUpdatedTime: 'Last updated time:',
      dailyMakerVolume: 'Daily Maker Volume',
      rewardsEstimated: 'Rewards (Estimated)',
      programEligibility: 'Program Eligibility',
      makerVolumeContribution: 'Maker Volume Contribution',
      dailyMakerVolumeContribution: 'Daily Maker Volume Contribution',
      rewardsDistributionByAddress: 'Rewards Distribution by Address',
      addressIsIneligible:
        'The address is ineligible because it has not been opted out of Trade & Earn rewards. ',
      programEligibilityTooltip:
        'The current epoch can be qualified for by obtaining next epoch eligibility at the end of the prior epoch, or by maintaining ≥ 0.25% of the daily total exchange maker volume of eligible markets for 3 days in a row in the current epoch. For next epoch eligibility, see Maker Volume Contribution.',
      makerVolumeContributionTooltip:
        'To obtain automatic eligibility for the next epoch, the address must account for ≥ 0.25% of the total cumulative exchange maker volume of eligible markets from the first day the address qualifies in the epoch through the last day of the epoch. This figure is displayed here.',
      enterValidAddress:
        'Please enter a participating Injective address to see results'
    },

    snapShot: {
      snapshots: 'Snapshots',
      address: 'Address',
      scoreSnapshots: 'Score Snapshots',
      timestamp: 'Timestamp (UTC)',
      liquidityScore: 'Liquidity Score',
      uptime: 'Uptime',
      totalVolume: 'Total Volume',
      totalScore: 'Total Score',
      refreshSnapshots: 'Refresh Snapshots'
    }
  }
}
