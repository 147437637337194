import { NetworkErrorType } from '@/types'

export const getNetworkErrorFromStoreActionType = (
  type: string
): NetworkErrorType | undefined => {
  switch (true) {
    case ['dmm/fetchEpochs'].includes(type):
      return NetworkErrorType.Epoch
    case ['dmm/fetchCurrentEpochMarketSummary'].includes(type):
      return NetworkErrorType.Markets
    case ['dmm/fetchTotalScores'].includes(type):
      return NetworkErrorType.Scores
    case ['dmm/fetchRewardsDistribution'].includes(type):
      return NetworkErrorType.RewardsDistribution
    case ['dmm/fetchTotalScoresHistory'].includes(type):
      return NetworkErrorType.Snapshots
    case ['dmm/fetchRewardsEligibility'].includes(type):
      return NetworkErrorType.Eligibility
    default:
  }
}
