import { defineRule } from 'vee-validate'
import { getEthereumAddress } from '@injectivelabs/sdk-ts'
import { BigNumberInBase } from '@injectivelabs/utils'

export const isAddressFormat = (value: string): boolean => {
  const valueTrimmed = value.trim()

  if (
    valueTrimmed.toLowerCase().startsWith('inj') &&
    valueTrimmed.length === 42
  ) {
    try {
      return !!getEthereumAddress(value)
    } catch (error: any) {
      return false
    }
  }

  return false
}

export const errorMessages = {
  email: () => 'This field should be a valid email',
  accountRequired: () => 'Address is required.',
  between: (min: string, max: string) =>
    `The value must be between ${min} and ${max}`,
  account: () => "Your search doesn't match an account address format",
  telegram: () => 'Valid telegram handle is required',
  insufficientFunds: () => `Insufficient Funds`,
  maximumAmount: (value: string) => `Value cannot be larger than ${value}`,
  minimumAmount: (value: string) => `Amount must be bigger than ${value}`
} as Record<string, any>

export const defineGlobalRules = () => {
  defineRule('email', (value: string) => {
    const validEmailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!validEmailPattern.test(String(value))) {
      return errorMessages.email()
    }

    return true
  })

  defineRule('required', (value: string) => {
    if (!value || !value.toString().length || Number(value) === 0) {
      return errorMessages.accountRequired()
    }

    return true
  })

  defineRule('positiveNumber', (value: string) => {
    if (Number(value) <= 0) {
      return 'This field should be a positive number'
    }

    return true
  })

  defineRule('between', (value: string, [min, max]: string[]) => {
    if (Number(min) > Number(value) || Number(max) < Number(value)) {
      return errorMessages.between(min, max)
    }

    return true
  })

  defineRule('address', (value: string) => {
    if (!isAddressFormat(value)) {
      return errorMessages.account()
    }

    return true
  })

  defineRule(
    'betweenRangeAndBalance',
    (value: string, [min, max, balance]: string[]) => {
      const valueInBigNumber = new BigNumberInBase(value)
      const balanceInBigNumber = new BigNumberInBase(balance)

      if (valueInBigNumber.lt(min)) {
        return errorMessages.minimumAmount(min)
      }

      if (valueInBigNumber.gt(balanceInBigNumber)) {
        return errorMessages.insufficientFunds()
      }

      if (valueInBigNumber.gt(max)) {
        return errorMessages.maximumAmount(max)
      }

      return true
    }
  )

  defineRule('telegram', (value: string) => {
    if (!value) {
      return true
    }

    /**
     * Between 5 and 32 characters, consisting of letters, numbers, and underscores
     **/
    const pattern = /^[A-Za-z0-9_]{5,32}$/

    if (value && !value.match(pattern)) {
      return errorMessages.telegram()
    }

    return true
  })
}

export default defineNuxtPlugin(() => {
  defineGlobalRules()
})
