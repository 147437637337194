import { default as envLG9o7Q1DyYMeta } from "/root/injective/injective-dmm-v2/pages/env.vue?macro=true";
import { default as indexc7SNIW49MoMeta } from "/root/injective/injective-dmm-v2/pages/index.vue?macro=true";
import { default as index9TGI8Am1TNMeta } from "/root/injective/injective-dmm-v2/pages/insurance-fund/index.vue?macro=true";
import { default as pending_45redemptionszm7xmXAV45Meta } from "/root/injective/injective-dmm-v2/pages/insurance-fund/pending-redemptions.vue?macro=true";
import { default as insurance_45fundVSJpiS76vCMeta } from "/root/injective/injective-dmm-v2/pages/insurance-fund.vue?macro=true";
import { default as maintenanceRePzOAiuQfMeta } from "/root/injective/injective-dmm-v2/pages/maintenance.vue?macro=true";
import { default as eligibilityxNqXIOSb5DMeta } from "/root/injective/injective-dmm-v2/pages/program/liquidity/eligibility.vue?macro=true";
import { default as indexAVL76kaPQnMeta } from "/root/injective/injective-dmm-v2/pages/program/liquidity/index.vue?macro=true";
import { default as rewardswKahh0F1KtMeta } from "/root/injective/injective-dmm-v2/pages/program/liquidity/rewards.vue?macro=true";
import { default as scoresnOAnJmyW5nMeta } from "/root/injective/injective-dmm-v2/pages/program/liquidity/scores.vue?macro=true";
import { default as snapshots8liFRGxIaZMeta } from "/root/injective/injective-dmm-v2/pages/program/liquidity/snapshots.vue?macro=true";
import { default as liquidityklgW4nfiwTMeta } from "/root/injective/injective-dmm-v2/pages/program/liquidity.vue?macro=true";
export default [
  {
    name: "env",
    path: "/env",
    meta: envLG9o7Q1DyYMeta || {},
    component: () => import("/root/injective/injective-dmm-v2/pages/env.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexc7SNIW49MoMeta || {},
    component: () => import("/root/injective/injective-dmm-v2/pages/index.vue")
  },
  {
    name: insurance_45fundVSJpiS76vCMeta?.name,
    path: "/insurance-fund",
    component: () => import("/root/injective/injective-dmm-v2/pages/insurance-fund.vue"),
    children: [
  {
    name: "insurance-fund",
    path: "",
    component: () => import("/root/injective/injective-dmm-v2/pages/insurance-fund/index.vue")
  },
  {
    name: "insurance-fund-pending-redemptions",
    path: "pending-redemptions",
    component: () => import("/root/injective/injective-dmm-v2/pages/insurance-fund/pending-redemptions.vue")
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceRePzOAiuQfMeta || {},
    component: () => import("/root/injective/injective-dmm-v2/pages/maintenance.vue")
  },
  {
    name: liquidityklgW4nfiwTMeta?.name,
    path: "/program/liquidity",
    component: () => import("/root/injective/injective-dmm-v2/pages/program/liquidity.vue"),
    children: [
  {
    name: "program-liquidity-eligibility",
    path: "eligibility",
    component: () => import("/root/injective/injective-dmm-v2/pages/program/liquidity/eligibility.vue")
  },
  {
    name: "program-liquidity",
    path: "",
    component: () => import("/root/injective/injective-dmm-v2/pages/program/liquidity/index.vue")
  },
  {
    name: "program-liquidity-rewards",
    path: "rewards",
    component: () => import("/root/injective/injective-dmm-v2/pages/program/liquidity/rewards.vue")
  },
  {
    name: "program-liquidity-scores",
    path: "scores",
    component: () => import("/root/injective/injective-dmm-v2/pages/program/liquidity/scores.vue")
  },
  {
    name: "program-liquidity-snapshots",
    path: "snapshots",
    component: () => import("/root/injective/injective-dmm-v2/pages/program/liquidity/snapshots.vue")
  }
]
  }
]