import { I18nMessageFunction } from '@/types'

export default {
  landing: {
    title: 'Injective Trading',
    description:
      'Access the most powerful decentralized network optimized for finance and traders',
    learnMore: 'Learn More',
    trading: 'Trading',

    whyInjective: {
      title: 'Why Injective',
      description:
        'Injective provides the world’s first MEV resistant orderbook for spot and derivatives trading. Injective is lightning fast and permissionless with industry leading reward programs for traders.'
    },

    waysToTrade: {
      title: '4 Ways to Trade',
      publicNodes: 'Public Nodes',
      exchangeDapp: 'Exchange dApps',
      selfHostedNodes: 'Self-hosted Nodes',
      automatedTradingDapps: 'Automated Trading dApps'
    },

    exchangeDapps: {
      title: 'Exchange dApps',
      description:
        'Injective powers leading exchanges across the globe. Join vibrant trading communities and choose from unmatched user experiences.',
      helix: 'Helix',
      wavely: 'Wavely',
      viewAll: 'View all'
    },

    automatedTradingDapps: {
      title: 'Automated Trading dApps',
      description:
        'State of the art trading automation tools provided by dApps built on Injective. Sit back and let the smart contracts do the work for you.',
      mito: 'Mito',
      blackPanther: 'Black Panther',
      viewAll: 'View all'
    },

    publicNodes: {
      title: 'Public Nodes',
      description:
        'Trade via institutional grade APIs hosted by the Injective community.',
      guideForPublicEndpoints: 'Guide for public endpoints',
      apiDocs: 'API Docs'
    },

    selfHostedNodes: {
      title: 'Self-hosted Nodes',
      description:
        'Trade via institutional grade APIs using your own  node. This is recommended for advanced traders as it can provide the most custom and dedicated environment for your needs.',
      howToRunANode: 'How to run a node',
      apiDocs: 'API Docs'
    },

    footer: {
      title: 'Join the community',
      twitter: {
        title: 'Twitter',
        description: ({ named }: I18nMessageFunction) =>
          `Follow us ${named('handler')}`
      },
      discord: {
        title: 'Discord',
        description: 'Join our Discord'
      }
    }
  }
}
